<template>
    <div class="agentManage">
        <div class="searchForm_box">
            <el-form
                    :inline="true"
                    size="small"
                    :model="searchForm"
                    class="demo-form-inline"
            >
                <el-form-item>
                    <el-input
                            placeholder="請輸入內容"
                            v-model="searchForm.searchTxt"
                            class="input-with-select">
                        <el-select
                                v-model="searchForm.searchChoose"
                                slot="prepend"
                                placeholder="請選擇"
                                style="width: 130px">
                            <el-option label="商品條碼" value="goods_no"></el-option>
                            <el-option label="商品名稱" value="goods_name"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="代理商">
                    <el-select v-model="agent_id" @change="getShop">
                        <el-option
                                v-for="item in agentList"
                                :key="item.agent_id"
                                :label="item.agent_name"
                                :value="item.agent_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="店鋪">
                    <el-select v-model="shop_id">
                        <el-option
                                v-for="item in shopList"
                                :key="item.shop_id"
                                :label="item.shop_name"
                                :value="item.shop_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="list" border class="table" v-loading="tableLoading">
<!--            <el-table-column width="100" label="ID" prop="id"></el-table-column>-->
            <el-table-column label="商品圖片" prop="img" width="90">
                <template slot-scope="scope">
                    <span v-if="scope.row.goods">
                        <el-image style="width: 50px; height: 50px" :src="scope.row.goods.img != ''? scope.row.goods.img : require('@/assets/image/default.jpg')" fit="contain">
                        </el-image>
                    </span>
                    <span v-else>
                        <el-image style="width: 50px; height: 50px" :src="require('@/assets/image/default.jpg')" fit="contain">
                        </el-image>
                    </span>
                </template>
            </el-table-column>
            <el-table-column width="500" label="商品名稱" prop="goods_name"></el-table-column>
            <el-table-column label="商品分類" prop="category_name"></el-table-column>
            <el-table-column label="條形碼" prop="goods_no"></el-table-column>
            <el-table-column label="店鋪" prop="shop.shop_name"></el-table-column>
            <el-table-column label="價格" prop="shop_price"></el-table-column>
            <el-table-column label="進價" prop="goods.cost_price"></el-table-column>
        </el-table>
        <!-- 分頁 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
        >
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "agentManage",

        data() {
            return {
                // 表格loading
                tableLoading: false,
                searchForm: {
                    searchTxt: "",
                    searchChoose: 'goods_no',
                    goods_name: "",
                    goods_no: "",
                    agent_id: "",
                    shop_id: "",
                },
                page: 1,
                pageSize:50,
                total: 0,
                list: [],
                agentList: [],
                shopList: [],
                agent_id: '',
                shop_id: '',
            };
        },
        created() {
            this.getList();
            this.getAgent();

        },
        mounted() {
        },
        methods: {
            search(){
                this.page = 1;
                this.getList();
            },
            getList() {
                this.tableLoading = true
                let params = {
                    page: this.page,
                    pageSize: this.pageSize,
                    goods_no:
                        this.searchForm.searchChoose == "goods_no" ? this.searchForm.searchTxt : "",
                    goods_name:
                        this.searchForm.searchChoose == "goods_name" ? this.searchForm.searchTxt : "",
                    shop_id:this.shop_id,
                    agent_id:this.agent_id,
                }
                this.$http('/admin/goods/shopGoods', params, 'post').then((res) => {
                    this.tableLoading = false
                    this.list = res.data.list;
                    this.total = res.data.count;
                }).catch(()=>{
                    this.tableLoading = false
                })
            },
            getAgent() {
                this.$http('/admin/agent/list', {
                    page: 1,
                    pageSize: 99999,
                }, 'post')
                    .then((res) => {
                        if (res.code == 200) {
                            this.agentList = res.data.list;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.data.msg,
                            });
                        }
                        this.getShop(res.data.agent_id,res.data.shop_id);
                        console.log('res.data.shop_id')
                        console.log(this.agent_id)
                        console.log(this.shop_id)
                    })
            },
            getShop(agentId,shop_id = '') {
                this.$http('/admin/shop/list', {
                    page: 1,
                    pageSize: 99999,
                    agent_id: agentId,
                }, 'post')
                    .then((res) => {
                        this.shopList = res.data.list;
                        this.shop_id = shop_id;
                        console.log('shop_id')
                        console.log(shop_id)
                    })
            },
            // 分頁
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getList();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/agentManage.scss";
</style>